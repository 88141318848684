
AOS.init({
  once: true,
})

$(window).on('load', function () {
  $('.loader').fadeOut();
});


//================ OwlCarousel2-2.3.4 =================//


$('#review').owlCarousel({
  loop:true,
  items: 2,
  margin: 30,
  dots: true,
  autoplay: true,
  smartSpeed: 1500,
  responsive : {
    // breakpoint from 0 up
    0 : {
      items:1,
    },
    // breakpoint from 992 up
    992 : {
      items:2,
    },
    // breakpoint from 1200 up
    1200 : {
      items:2,
    },
  }
})


$('.counter').counterUp({
  delay: 5,
  time: 1000
});


//================ Fancy Cursor Animation JS =================//

const cursor = cursorDot({
  easing: 7,
  diameter: 10,
  background: "#3A0088",
  borderColor: 'transparent',
})
cursor.over(".f-link", {
  background: '#fff',
  scale: 8,
  borderColor: 'transparent',
});
cursor.over(".close-hover", {
  background: '#fff',
  scale: 6,
  borderColor: 'transparent',
});
cursor.over(".overlay-hover", {
  background: '#fff',
  borderColor: 'transparent',
});
cursor.over(".cur-hover", {
  background: '#fff',
  scale: 8,
  borderColor: 'transparent',
});
cursor.over(".hover", {
  background: '#fff',
  scale: 1.5,
  borderColor: 'transparent',
});
cursor.over(".cur-hover1", {
  scale: 4,
  background: '#E61C5D',
  borderColor: 'transparent',
});


$('.js-tilt').tilt({
  maxTilt: 8,
})

$('.js-tilt-why-choose').tilt({
  maxTilt: 10,
})









